import { createContext } from "react";



export const DecoreItemContext = createContext()

// export const UpdateSelectedDectoreItem =(decoreItem)=>(
//     updateSelectedDecoreItem(decoreItem)
// )

// export DecoreItemContext