import { useContext } from "react"
import { NestaDecorOverlayScreenContext } from "../pages/nesta_decor/decor_overlay_context"

export const useRFQHandler = () => {
    const {updateRequestForQuote, isRFQVisible} = useContext(NestaDecorOverlayScreenContext)

    const showRFQScreen  = () =>{
        document.body.classList.add('no-scroll')
        updateRequestForQuote(true)
    }
    
    const hideRFQScreen  = () =>{
        updateRequestForQuote(false)
    }
    
    return{
        showRFQScreen,
        hideRFQScreen,
        isRFQVisible
    }
}